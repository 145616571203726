import { Row, Col } from "react-bootstrap";
import RightComponent from "./RightComponent";
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useEffect, useState } from "react";
import { deleteMouseOverApi, getAllMouseOver, mouseOverApi, updateMouseOverApi } from "../../../Api/Api";
import { ToastContainer, toast } from "react-toastify";
import SpinnerLoader from "../../Layout/Spinner";
export default function MouseOverSetting() {
    const [form, setForm] = useState({})
    const [updateForm, setUpdateForm] = useState({})
    const [allData, setAllData] = useState([])
    const [loader, setLoader] = useState(true)
    const [editPosition, setEditPosition] = useState(-1)
    const handleChange = async (event) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    const getAllMouse = async () => {
        const res = await getAllMouseOver()
        if (res.status === "success") {
            setAllData(res.data);
            setLoader(false)
        }
    }
    useEffect(() => {
        getAllMouse()
    }, [])
   
    const handleSubmit = async (event) => {
        event.preventDefault()
        const res = await mouseOverApi(form)
        if (res.status === "success") {
            getAllMouse()
            toast.success(res.message)
        }
        else {
            toast.error(res.message)
        }
    }

    const updateChange = async (event)=>{
        setUpdateForm({ ...updateForm, [event.target.name]: event.target.value })
    }
    const updateSubmit = async (id)=>{
        console.log(updateForm,id)
        if(Object.values(updateForm).length > 0)
        {
            const res = await updateMouseOverApi(updateForm,id)
            if(res.status === "success"){
                getAllMouse()
                toast.success(res.message)
            }
            else{
                toast.error(res.message)
            }
        }
        else{
            toast.error("Please  Fill Out this field !!")
        }
    }

    const deleteRow = async (id) => {
        const res = await deleteMouseOverApi(id)
        if (res.status === "success") {
            getAllMouse()
            toast.success(res.message)

        }
    }
    return (
        <RightComponent>
            <Row>
                <Col>
                    <h3 className="bold-700">Hover Settings</h3>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={12} className="card py-4 rounded-0  text-white">
                    <form onSubmit={handleSubmit}>
                        <input type="text" className="py-1 ps-2" name="title" onChange={handleChange} placeholder="Enter Hover Key" style={{ width: '30%', height: '40px' }}></input>
                        <input type="text" className="ms-3 py-1 ps-2" name="value" onChange={handleChange} placeholder="Enter Hover value" style={{ width: '30%', height: '40px' }}></input>
                        <input type="text" className="ms-3 py-1 ps-2" name="text_color" onChange={handleChange} placeholder="Enter Text Color Code" style={{ width: '30%', height: '40px' }}></input>
                        <input type="text" className="py-1 ps-2 mt-2" name="box_color" onChange={handleChange} placeholder="Enter Box Color Code" style={{ width: '30%', height: '40px' }}></input>
                        <input type="submit" className="btn btn-sm btn-primary ms-3 rounded-0 px-3" value="Save" style={{ height: '40px' }}></input>
                    </form>
                </Col>
            </Row>

            <Row className="mt-2 justify-content-center">
                <Col md={2}><SpinnerLoader status={loader} /></Col>
                <Col md={12} className="card py-4 rounded-0  text-white">

                    <table className="table text-center fns-2">

                        <thead className="bg-success text-white">
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Hover Key Name</th>
                                <th scope="col">Hover Key value</th>
                                <th scope="col">Hover Text Color</th>
                                <th scope="col">Hover Box Color</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Edit</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                allData.map((ele, index) =>
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <input type="text" name="title" defaultValue={ele.title} onChange={updateChange} className={editPosition !== index ? `input-no-design` : `input-edit-active`} readOnly={editPosition !== index ? true : false}></input>
                                        </td>
                                        <td>
                                            <input type="text" name="value" defaultValue={ele.value} onChange={updateChange} className={editPosition !== index ? `input-no-design` : `input-edit-active`} readOnly={editPosition !== index ? true : false}></input>
                                        </td>
                                        <td>
                                            <input type="text" name="text_color" defaultValue={ele.text_color} onChange={updateChange} className={editPosition !== index ? `input-no-design` : `input-edit-active`} readOnly={editPosition !== index ? true : false}></input>
                                        </td>
                                        <td>
                                            <input type="text" name="box_color" defaultValue={ele.box_color} onChange={updateChange} className={editPosition !== index ? `input-no-design` : `input-edit-active`} readOnly={editPosition !== index ? true : false}></input>
                                          
                                        </td>
                                        <td><AiFillDelete className="text-danger fs-5" onClick={() => deleteRow(ele._id)} style={{ cursor: 'pointer' }} /></td>
                                        <td><AiFillEdit className="text-warning fs-5" style={{ cursor: 'pointer' }} onClick={() => {
                                            setEditPosition(index) 
                                            setUpdateForm({})
                                            }} /></td>
                                        <td>  {editPosition === index?<><button className="btn bt-sm btn-success ms-3 fns-2" onClick={()=>updateSubmit(ele._id)}>Save</button> <button className="btn bt-sm btn-danger ms-1 fns-2" onClick={()=>setEditPosition(-1)}>Cancel</button></>:null}</td>
                                    </tr>
                                )
                            }


                        </tbody>
                    </table>
                </Col>
            </Row>
            <ToastContainer />
        </RightComponent>

    )
}