import { Col,Row } from "react-bootstrap";
import RightComponent from "../DashboardList/RightComponent";
import SpinnerLoader from "../../Layout/Spinner";
import { useState,useEffect } from "react";
import { getAllUserListApi } from "../../../Api/Api";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { API_IMG_URL } from "../../../Constant/Url";
export default function User() {
    const [loader, setLoader] = useState(true)
    const [allData, setAllData] = useState([])
    const getApiAll = async () => {
        const res = await getAllUserListApi()
        if (res.status === "success") {
            setAllData(res.data);
            setLoader(false)
        }
    }
    useEffect(() => {
       getApiAll()
    }, [])
    return (
        <>
            <RightComponent>
                <Row>
                    <Col>
                        <h3 className="bold-700">Total Users {allData.length}</h3>
                    </Col>
                </Row>
                <Row className="mt-2 justify-content-center">
                <Col md={2}><SpinnerLoader status={loader} /></Col>
                <Col md={12} className="card py-4 rounded-0  text-white">

                    <table className="table fns-2">

                        <thead className="bg-success text-white">
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Email Id</th>
                                <th scope="col">Profile Image</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Edit</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                allData.map((ele, index) =>
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <th scope="row">{ele.name}</th>
                                        <th scope="row">{ele.email}</th>
                                        <th scope="row"><img src={`${API_IMG_URL}${ele.image_path}`} width={40} height={40} style={{borderRadius:"50%"}}></img></th>
                                        <td><AiFillDelete className="text-danger fs-5" /></td>
                                        <td><AiFillEdit className="text-warning fs-5" style={{ cursor: 'pointer' }}/></td>
                                    </tr>
                                )
                            }


                        </tbody>
                    </table>
                </Col>
            </Row>
            </RightComponent>
        </>
    )
} 