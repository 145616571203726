import { Spinner } from "react-bootstrap"
export default function SpinnerLoader(props){
    return(
        <>
        {
            props.status? <Spinner animation="border" size="xl" className="text-center my-2"></Spinner>:null
        }
        
        </>
    )
}