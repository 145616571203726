export const Route = {
    login:"login",
    getAllPoolAccount:"getAllPoolAccount",
    getAllDirectAccount:"getAllDirectAccount",
    getAllAccount:"getAllAccount",
    getAllPool:"getAllPool",
    getAllUser:"getAllUser",
    addPoolAssignAccount:"addPoolAssignAccount/:id",
    mouseOver:"mouseover",
    learn:"learn",
    strategy:"strategy",
    account:"account",
    getAllUserList:"getAllUserList",
}