export const getToken=()=>{
    if(localStorage.getItem('admin-login-info')){
        return JSON.parse(localStorage.getItem('admin-login-info')).token;
    }
}
export const  getAllStorage = ()=>{
    return JSON.parse(localStorage.getItem('admin-login-info'));
}
export const  setStorage = (storageData)=>{
    return localStorage.setItem('admin-login-info',JSON.stringify(storageData));
}

export const clearStorage = ()=>{
    localStorage.removeItem('admin-login-info')
}

export const isStorage =  ()=>{
    if(localStorage.getItem('admin-login-info')){
        return true
    }
    else{
        return false;
    }
}