import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { ToastContainer, toast } from "react-toastify";
import { strategyApi } from "../../../Api/Api";

export default function StrategyForm(props) {
    const [strategyForm,setStrategyForm] = useState()
    const [tab2,setTab2] = useState()
    const [tab1,setTab1] = useState()
    const [show,setShow] = useState(false)
    const handleChange = async (event)=>{
        setStrategyForm({...strategyForm,[event.target.name]:event.target.value});
    }
    const handleSubmit = async (event)=>
    {
        event.preventDefault();
        const strategyData = {
            product_name:strategyForm.product_name,
            // product_tab_1:strategyForm.product_tab_1,
            // product_tab_2:strategyForm.product_tab_2,
            product_caption:strategyForm.product_caption,
            product_content_1:tab1,
            product_content_2:tab2
        }
        const res = await strategyApi(strategyData)
        if(res.status === "success"){
            toast.success(res.message)
            props.saveUpdate()
        }
        else{
            toast.error(res.message);
        }
    }

    return (
        <>
            <Row className="mt-2">
                <Col md={12}>
                    <button className="btn btn-danger" onClick={()=>setShow(!show)}>{show?'Hide Form':'Create New Strategy'}</button>
                </Col>
                {
                    show?
                <Col className="card py-2 rounded-0 bg-white text-dark mt-2">
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <p className="my-1">Enter Strategy Name </p>
                                <input className="form-control" required name="product_name" onChange={handleChange} placeholder="Enter Strategy Name"></input>
                            </Col>
                            {/* <Col>
                                <p className="my-1">Enter Tab1 Name </p>
                                <input className="form-control" required name="product_tab_1" onChange={handleChange} placeholder="Enter Tab1 Name"></input>
                            </Col>
                            <Col>
                                <p className="my-1">Enter Tab2 Name </p>
                                <input className="form-control" required name="product_tab_2" onChange={handleChange} placeholder="Enter Tab2 Name"></input>
                            </Col> */}
                            <Col>
                                <p className="my-1">Enter Caption </p>
                                <input className="form-control" required name="product_caption" onChange={handleChange} placeholder="Enter Caption"></input>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <p className="my-1">Enter Tab1 Content </p>
                                <CKEditor initData="" type="classic" onChange={(event)=>setTab1(event.editor.getData())}>

                                </CKEditor>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <p className="my-1">Enter Tab2 Content </p>
                                <CKEditor initData="" type="classic" onChange={(event)=>setTab2(event.editor.getData())}>

                                </CKEditor>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                              <input type="submit" value="Add Strategy" className="btn btn-primary"></input>
                            </Col>
                        </Row>
                    </form>
                </Col>
                :
                null
                }
            </Row>
            <ToastContainer />
        </>
    )
}