import { Row, Col } from "react-bootstrap";
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useEffect, useState } from "react";
import { deleteAccountyApi, getAllAccountApi, updateAccountApi } from "../../../Api/Api";
import { ToastContainer, toast } from "react-toastify";
import SpinnerLoader from "../../Layout/Spinner";
import RightComponent from "../DashboardList/RightComponent";
export default function Account() {
    const [allData, setAllData] = useState([])
    const [loader, setLoader] = useState(true)
    const [formData,setFormData] = useState();
    const handleChange = async (event, id) => {
        console.log(event.target.checked)
        const data = {
            virtual: event.target.checked
        }
        const res = await updateAccountApi(data, id)
        if (res.status === "success") {
            toast.success(res.message)
            getAllAccount()
        }
        else {
            toast.error(res.message)
        }
    }
    const getAllAccount = async () => {
        const res = await getAllAccountApi()
        if (res.status === "success") {
            setAllData(res.data);
            setLoader(false)
        }
    }

    const onHandleChange = (event)=>{
            setFormData({...formData,[event.target.name]:event.target.value});
    }
    const updateFormData = async (id)=>{
        const res = await updateAccountApi(formData, id)
        if (res.status === "success") {
            toast.success(res.message)
            getAllAccount()
        }
    }
    useEffect(() => {
        getAllAccount()
    }, [])





    const deleteRow = async (id) => {
        const res = await deleteAccountyApi(id)
        if (res.status === "success") {
            getAllAccount()
            toast.success(res.message)

        }
    }
    return (
        <RightComponent>
            <Row>
                <Col>
                    <h3 className="bold-700">Account Settings</h3>
                </Col>
            </Row>


            <Row className="mt-2 justify-content-center">
                <Col md={2}><SpinnerLoader status={loader} /></Col>
                <Col md={12} className="card py-4 rounded-0  text-white">

                    <table className="table text-center fns-2 ">

                        <thead className="bg-success text-white" >
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Account</th>
                                <th scope="col">Name</th>
                                <th scope="col">Equity</th>
                                <th scope="col">Balance</th>
                                <th scope="col">Principal</th>
                                <th scope="col">Account Type</th>
                                <th scope="col">Set Virtual</th>
                                <th scope="col">Strategy</th>
                                <th scope="col">Return Per Annuam</th>
                                <th scope="col">Instrument</th>
                                <th scope="col">Risk On Investment</th>
                                <th scope="col">Report</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Update</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                allData.map((ele, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{ele.account}</td>
                                        <td>{ele.name}</td>
                                        <td>{ele.equity}</td>
                                        <td>{ele.balance}</td>
                                        <td>{ele.principal}</td>
                                        <td>{ele.direct === true ? "Direct Account" : ele.pool === true ? "Real Account" : ele.virtual ? "Virtual Account" : ""}
                                        </td>
                                        <td>
                                            {(ele.direct || ele.pool) ? null : <input type="checkbox" checked={ele.virtual ? true : false} onChange={(event) => handleChange(event, ele._id)}></input>}
                                        </td>
                                        <td><input type="text" defaultValue={ele?.strategy} name="strategy" onChange={onHandleChange}></input></td>
                                        <td><input type="text" defaultValue={ele?.return_peranum} name="return_peranum" onChange={onHandleChange}></input></td>
                                        <td><input type="text" defaultValue={ele?.instrument} name="instrument" onChange={onHandleChange}></input></td>
                                        <td><input type="text" defaultValue={ele?.risk_on_investment} name="risk_on_investment" onChange={onHandleChange}></input></td>
                                        <td><input type="text" defaultValue={ele?.report} name="report" onChange={onHandleChange}></input></td>
                                        <td><AiFillDelete className="text-danger fs-5" onClick={() => deleteRow(ele._id)} style={{ cursor: 'pointer' }} /></td>
                                        <td><button type="button" onClick={()=>updateFormData(ele._id)}>Update</button></td>

                                    </tr>
                                )
                            }


                        </tbody>
                    </table>
                </Col>
            </Row>
            <ToastContainer />
        </RightComponent>

    )
}