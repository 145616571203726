import { Row,Col } from "react-bootstrap";
import RightComponent from "../DashboardList/RightComponent";
import { useState,useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import SpinnerLoader from "../../Layout/Spinner";
import { deleteLearnApi, getAllLearnApi, leranApi, updateLearnApi } from "../../../Api/Api";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEdit,AiFillDelete } from "react-icons/ai";

export default function Training(){
    
    const [formData,setFormData] = useState()
    const [editorText,setEditorText]  = useState()
    const [loader, setLoader] = useState(true)
    const [newPage,setNewPage] = useState(false)
    const [allData,setAllData] = useState([])
    const [value,setValue] = useState({})
    const [updateBtn,setUpdateBtn] = useState(false)
 
    const handleChange = async (event)=>{
        setFormData({...formData,[event.target.name]:event.target.value})    
    
    }
  
    const handleEditor = (event)=>{
        setEditorText({page_content:event.editor.getData()})
    }

    const getAllLearn = async () => {
        const res = await getAllLearnApi()
        if (res.status === "success") {
            setAllData(res.data);
            setLoader(false)
        }
    }
    useEffect(() => {
        getAllLearn()
    }, [])
    const handleSubmit  =  async (event)=>{
        event.preventDefault()
        const combineData = {
            page_heading:formData?.page_heading,
            page_name:formData?.page_name,
            page_title:formData?.page_title,
            page_content:editorText?.page_content
        }
        const res = await leranApi(combineData)
        if(res.status === "success"){
            toast.success(res.message)
            getAllLearn()
        }
        else
            toast.error(res.message)
    }

    const handleEditorUpdate = (event)=>{
        setEditorText({page_content:event.editor.getData()})
    }

    const handleChangeUpdate = async (event)=>{
        setFormData({...formData,[event.target.name]:event.target.value})    
    
    }
    const handleSubmitUpdate  =  async (event)=>{
        event.preventDefault()
        const combineData = {
            page_heading:formData?.page_heading,
            page_name:formData?.page_name,
            page_title:formData?.page_title,
            page_content:editorText?.page_content
        }
        if(combineData.page_content !=undefined){
          const res =   await updateLearnApi(combineData,formData.id)
          if(res.status === "success"){
            getAllLearn()
            toast.success(res.message)
          }
          else{
            toast.error(res.message)
          }
        }
        else{
            toast.error("Fill Out This Field !!")
        }
       


    }


   
    const updateFun = async (data)=>{
        setValue({...data})
        setFormData({
            page_heading:data.page_heading,
            page_name:data.page_name,
            page_title:data.page_title,
            page_content:data.page_content,
            id:data._id
        })

        if(updateBtn){
            toast.error("Please Close Update Form")
        }
        else{
            setUpdateBtn(true);
        }
       
    }

    const deleteRow = async (id) => {
        const res = await deleteLearnApi(id)
        if (res.status === "success") {
            getAllLearn()
            toast.success(res.message)

        }
    }
    return(
        <RightComponent>
            <Row>
                <Col>
                    <h3 className="bold-700">Learn</h3>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="card py-2 rounded-0 bg-white text-dark d-flex">
                    <p><button className="btn btn-primary bold-600 " style={{width:'200px'}} onClick={()=>setNewPage(!newPage)}>{newPage?'Hide':'Create New Page'}</button>
                    {
                    updateBtn?
                    <button className="btn btn-danger bold-600 mx-4" style={{width:'200px'}} onClick={()=>setUpdateBtn(false)}>Hide Update Box</button>
                    :null
                    }
                    </p>
                    </Col>
            </Row>

            {
                newPage?
                <Row className="mt-2">
                <Col md={12} className="card py-4 rounded-0 ">
                    <form onSubmit={handleSubmit}>
                    <p className="text-dark m-0">Enter Page Name</p>
                    <input type="text" className="py-1 ps-2 my-3" required name="page_heading"  onChange={handleChange}  placeholder="Enter Page Heading Menu" style={{ width: '30%', height: '40px' }}></input>
                    <p className="text-dark m-0">Enter Page Name</p>
                    <input type="text" className="py-1 ps-2 my-3" required name="page_name"  onChange={handleChange}  placeholder="Enter Page Name" style={{ width: '30%', height: '40px' }}></input>
                    <p className="text-dark m-0">Enter Page Title</p>
                    <input type="text" className="py-1 ps-2 my-3" required name="page_title"  onChange={handleChange}  placeholder="Enter Page Title" style={{ width: '30%', height: '40px' }}></input>
                    <p className="text-dark m-0">Enter Page Content</p>
                    <CKEditor  
                        initData=""
                        name="my-ckeditor"
                        id="edit"
                        onChange= {handleEditor}
                        type="classic"
                  />
                    <p className="text-center"><input type="submit" className="btn btn-sm btn-primary ms-3 rounded-0 px-3 my-5" value="Save" style={{ height: '40px' }} ></input></p>
                    </form>
                </Col>
                </Row>
                :null
            }



            
                {
                updateBtn?
                <Row className="mt-2">
                <Col md={12} className="card py-4 rounded-0 ">
                    <form onSubmit={handleSubmitUpdate}>
                    <p className="text-dark m-0">Enter Page Name</p>
                    <input type="text" className="py-1 ps-2 my-3"  defaultValue={value.page_heading} required name="page_heading"   onChange={handleChangeUpdate}  placeholder="Enter Page Heading Menu" style={{ width: '30%', height: '40px' }}></input>
                    <p className="text-dark m-0">Enter Page Name</p>
                    <input type="text" className="py-1 ps-2 my-3" defaultValue={value.page_name} required name="page_name"  onChange={handleChangeUpdate}  placeholder="Enter Page Name" style={{ width: '30%', height: '40px' }}></input>
                    <p className="text-dark m-0">Enter Page Title</p>
                    <input type="text" className="py-1 ps-2 my-3" defaultValue={value.page_title}  required name="page_title"  onChange={handleChangeUpdate}  placeholder="Enter Page Title" style={{ width: '30%', height: '40px' }}></input>
                    <p className="text-dark m-0">Enter Page Content</p>
                    <CKEditor  
                        initData={value.page_content.replaceAll("&lt;","<")}
                        name="my-ckeditor"
                        id="edit"
                        onChange= {handleEditorUpdate}
                        type="classic"
                  />
                    <p className="text-center"><input type="submit" className="btn btn-sm btn-primary ms-3 rounded-0 px-3 my-5" value="Save" style={{ height: '40px' }} ></input></p>
                    </form>
                </Col>
                </Row>
                :null
            }
          
            <Row className="mt-2 justify-content-center">
                <Col md={2}><SpinnerLoader status={loader} /></Col>
                <Col md={12} className="card py-4 rounded-0  text-white">

                    <table className="table text-center fns-2">

                        <thead className="bg-success text-white">
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Page Heading</th>
                                <th scope="col">Page Name</th>
                                <th scope="col">Page Title</th>
                                <th scope="col">Page Content</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Edit</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                        {
                                allData.map((ele, index) =>
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <th scope="row">{ele.page_heading}</th>
                                        <td>
                                           {ele.page_name}
                                        </td>
                                        <td>
                                        {ele.page_title}
                                        </td>
                                        <td dangerouslySetInnerHTML={{__html:ele.page_content.replaceAll("&lt;","<").replaceAll("&gt;",">").trim()}}>
                                        </td>
                                      
                                        <td><AiFillDelete className="text-danger fs-5" onClick={() => deleteRow(ele._id)} style={{ cursor: 'pointer' }} /></td>
                                        <td><AiFillEdit className="text-warning fs-5" style={{ cursor: 'pointer' }}  onClick={()=>updateFun(ele)}/>
                                        </td>
                                      
                                    </tr>
                                )
                            }


                        </tbody>
                    </table>
                </Col>
            </Row>
            <ToastContainer />
        </RightComponent>
    )
}