import { API_URL } from "../Constant/Url";
import { Route } from "../Routes/Route";
import { getToken } from "../Constant/StorageInfo";
const METHOD = {
    GET:"GET",
    POST:"POST",
    PUT:"PUT",
    DELETE:"DELETE"
}
const APPLICATION_HEADER = {"Content-Type":"application/json"}
const APPLICATION_JSON = {
    "authorization":`bearer ${getToken()}`,
    "Content-Type":"application/json"
}
export const loginApi = async (data)=>{
    console.log(data)
    const HeadersObj = {
        method:METHOD.POST,
        headers:APPLICATION_HEADER,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.login,HeadersObj);
    return await response.json();
}
export const getSinglePoolInfo = async (poolID)=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    // const response = await fetch(`${URL.POOL}${Route.getSinglePoolInfo}/${poolID}`,HeadersObj);
    // return await response.json();
}

export const mouseOverApi = async (data)=>{
    const HeadersObj = {
        method:METHOD.POST,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.mouseOver,HeadersObj);
    return await response.json();
}

export const updateMouseOverApi = async (data,id)=>{
    console.log(APPLICATION_JSON);
    const HeadersObj = {
        method:METHOD.PUT,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.mouseOver+`/${id}`,HeadersObj);
    return await response.json();
}

export const getAllMouseOver = async ()=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.mouseOver,HeadersObj);
    return await response.json();
}

export const deleteMouseOverApi = async (id)=>{
    const HeadersObj = {
        method:METHOD.DELETE,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.mouseOver+`/${id}`,HeadersObj);
    return await response.json();
}

export const leranApi = async (data)=>{
    const HeadersObj = {
        method:METHOD.POST,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.learn,HeadersObj);
    return await response.json();
}

export const updateLearnApi = async (data,id)=>{
    const HeadersObj = {
        method:METHOD.PUT,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.learn+`/${id}`,HeadersObj);
    return await response.json();
}

export const getAllLearnApi = async ()=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.learn,HeadersObj);
    return await response.json();
}

export const deleteLearnApi = async (id)=>{
    const HeadersObj = {
        method:METHOD.DELETE,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.learn+`/${id}`,HeadersObj);
    return await response.json();
}


export const strategyApi = async (data)=>{
    const HeadersObj = {
        method:METHOD.POST,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.strategy,HeadersObj);
    return await response.json();
}

export const updateStrategyApi = async (data,id)=>{
    const HeadersObj = {
        method:METHOD.PUT,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.strategy+`/${id}`,HeadersObj);
    return await response.json();
}

export const getAllStrategyApi = async ()=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.strategy,HeadersObj);
    return await response.json();
}
export const getSingleStrategyApi = async (id)=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.strategy+"/"+id,HeadersObj);
    return await response.json();
}

export const deleteStrategyApi = async (id)=>{
    const HeadersObj = {
        method:METHOD.DELETE,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.strategy+`/${id}`,HeadersObj);
    return await response.json();
}

export const deleteAccountyApi = async (id)=>{
    const HeadersObj = {
        method:METHOD.DELETE,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.account+`/${id}`,HeadersObj);
    return await response.json();
}
export const getAllAccountApi = async ()=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.account,HeadersObj);
    return await response.json();
}
export const getAllUserListApi = async ()=>{
    const HeadersObj = {
        method:METHOD.GET,
        headers:APPLICATION_JSON,
    }
    const response = await fetch(API_URL+Route.getAllUserList,HeadersObj);
    return await response.json();
}
export const updateAccountApi = async (data,id)=>{
    const HeadersObj = {
        method:METHOD.PUT,
        headers:APPLICATION_JSON,
        body:JSON.stringify(data)
    }
    const response = await fetch(API_URL+Route.account+`/${id}`,HeadersObj);
    return await response.json();
}
