import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Auth/Login";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { Dashboard } from "./Components/Dashboard/Dashboard";
import DashboardHome from "./Components/Dashboard/DashboardList/DashboardHome";
import MouseOverSetting from "./Components/Dashboard/DashboardList/MouseOverSetting";
import Main from "./Context/Main";
import Training from "./Components/Dashboard/Training/Training";
import Strategies from "./Components/Dashboard/Strategies/Strategies";
import StrategyEdit from "./Components/Dashboard/Strategies/StrategyEdit";
import Account from "./Components/Dashboard/Account/Account";
import User from "./Components/Dashboard/Users/User";
function App() {
  return (
    <>
      <Router>
        <Main>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<DashboardHome />}></Route>

              <Route path="strategies">
                <Route path=""  element={<Strategies />}></Route>
                <Route path=":id"  element={<StrategyEdit />}></Route>
              </Route>
              <Route path="users" element={<User />}></Route>
              <Route path="account" element={<Account />}></Route>
              <Route path="mouseover" element={<MouseOverSetting />}></Route>
              <Route path="training" element={<Training />}></Route>
            </Route>
          </Routes>
        </Main>
      </Router>
    </>
  );
}

export default App;
