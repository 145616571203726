import { Col } from "react-bootstrap";

export default function RightComponent({children}){
return(
    <>
    <Col md={10} className="p-3 px-4">
        {children}
    </Col>
    </>
)
}