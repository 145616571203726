import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../Constant/Url";
import { useContext } from "react";
import { userContext } from "../../Context/UserContext";

export default function DashboardHeader() {
    const {logout} = useContext(userContext)
    return (
        <>

            <Container fluid className="bg-header sticky-top" style={{zIndex:'9999'}}>
                <Row className="align-items-center">
                    <Col className="py-1">
                    <p className="align-items-center d-flex m-0 py-1">
                        <img src={`${IMAGE_URL}poolsifi_logo.png`} width={40} height={40} alt="logo" style={{verticalAlign:'middle'}}></img>
                        <span className="fs-3 bold-700 text-white ms-2" style={{verticalAlign:'middle'}}> PoolsiFi</span>
                    </p>
                    </Col>
                    <Col>
                        <ul className="nav justify-content-end fns-2">
                            {/* <li className="nav-item">
                                <Link className="nav-link text-black bold-400 text-white" to="">Total Customer : 5000</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link text-black bold-400 text-white" to="">Hi Admin</Link>
                            </li>
                            <li className="nav-item">
                                <button className="btn  btn-sm btn-danger nav-link text-white   rounded-0" onClick={()=>logout()}>Logout</button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    )
}