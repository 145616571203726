import { Col } from "react-bootstrap";

export default function SideMenu({children}){
return(
    <>
    <Col md={2} className="p-0 mt-3 sticky-top" style={{background:'#fff',height:'98vh'}}>
        {children}
    </Col>
    </>
)
}