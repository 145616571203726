import { useState } from "react";
import { Col, Container,Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import { loginApi } from "../../Api/Api";
import { setStorage } from "../../Constant/StorageInfo";
import { IMAGE_URL } from "../../Constant/Url";

export default function Login()
{
    const [loginForm,setLoginForm] = useState({accessKey:''})
    const navigate = useNavigate()
    function onHandleChange(event){
        setLoginForm({...loginForm,[event.target.name]:event.target.value})
    }
    async function handleSubmit(){
        const response = await loginApi(loginForm);
        if(response.status === "success"){
            setStorage({token:loginForm.accessKey})
            toast.success(response.message)
            setTimeout(function(){
                navigate("/dashboard");
            },2000)
        }
        else{
            
            toast.error(response.message)
        }
           

    }
    return (
        <>
        <Container fluid>
            <Row className="justify-content-center">
                <Col md={4} className="mt-5">
                    <p className="text-center"><img src={`${IMAGE_URL}poolsifi_logo.png`} width={90} height={90} alt="logo"></img></p>
                    <h3 className="text-center fs-1 bold-800">PoolsiFi</h3>
                    <div className="d-flex mt-5">
                        <input type="password" name="accessKey" onChange={onHandleChange} placeholder="Enter Password" className="form-control form-inline rounded-0" style={{width:'60%'}}></input>
                        <input type="submit" value="Login" onClick={handleSubmit} className="btn btn-dark form-inline mx-3 rounded-0" style={{width:'30%',fontWeight:'bold'}}></input>
                    </div>
                  
                </Col>
            </Row>
        </Container>
        <ToastContainer />
        </>
    )
}