import { Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../Layout/DashboardHeader";
import DashboardMenu from "./DashboardList/DashboardMenu";

export function Dashboard()
{
    return(
        <>
        <DashboardHeader />
        <Container fluid>
            <Row>
                <DashboardMenu />
                <Outlet />
            </Row>
        </Container>
        </>
    )
}