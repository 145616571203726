import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { ToastContainer, toast } from "react-toastify";
import { getSingleStrategyApi, updateStrategyApi } from "../../../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
import RightComponent from "../DashboardList/RightComponent";

export default function StrategyEdit(props) {
    const [strategyForm,setStrategyForm] = useState()
    const [tab2,setTab2] = useState()
    const [tab1,setTab1] = useState()
    const [strategy,setStrategy] = useState()
    const [show,setShow] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate()
    const handleChange = async (event)=>{
        setStrategyForm({...strategyForm,[event.target.name]:event.target.value});
    }
    useEffect(()=>{
        getSingleStrategyApi(id).then(value=>{
            setStrategy(value.data)
        })
        setTimeout(()=>{
            setShow(true)
        },1000)

    },[id])

  
    const handleSubmit = async (event)=>
    {
        event.preventDefault();
        const strategyData = {
            product_name:strategyForm?.product_name?strategyForm.product_name:strategy.product_name,
            // product_tab_1:strategyForm.product_tab_1,
            // product_tab_2:strategyForm.product_tab_2,
            product_caption:strategyForm?.product_caption?strategyForm.product_caption:strategy.product_caption,
            product_content_1:tab1?tab1:strategy.product_content_1,
            product_content_2:tab2?tab2:strategy.product_content_2
        }

        console.log(strategyData)
        const res = await updateStrategyApi(strategyData,id)
        if(res.status === "success"){
            toast.success(res.message)
        }
        else{
            toast.error(res.message);
        }
    }

    return (
        <>
        <RightComponent>
            <Row>
                <Col>
                    <h3 className="bold-700">Edit Strategies</h3>
                </Col>
            </Row>
       
            <Row className="mt-2">
                <Col className="card py-2 rounded-0 bg-white text-dark mt-2">
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <p className="my-1">Enter Strategy Name </p>
                                <input className="form-control" defaultValue={strategy?.product_name} required name="product_name" onChange={handleChange} placeholder="Enter Strategy Name"></input>
                            </Col>
                            <Col>
                                <p className="my-1">Enter Caption </p>
                                <input className="form-control" defaultValue={strategy?.product_caption} required name="product_caption" onChange={handleChange} placeholder="Enter Caption"></input>
                            </Col>
                        </Row>
{
                show?
                        <>
                        <Row className="mt-3">
                            <Col>
                                <p className="my-1">Enter Tab1 Content</p>
                                <CKEditor  
                                initData={strategy?.product_content_1?.replaceAll("&lt;","<")} 
                                onChange={(event)=>setTab1(event.editor.getData())}
                                type="classic"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <p className="my-1">Enter Tab2 Content </p>
                                <CKEditor 
                                initData={strategy?.product_content_2?.replaceAll("&lt;","<")}
                                type="classic" 
                                onChange={(event)=>setTab2(event.editor.getData())} 
                                />

                             
                            </Col>
                        </Row>
                        </>
                        :null
                        }



                        <Row className="mt-3">
                            <Col>
                              <input type="submit" value="Update Strategy" className="btn btn-primary"></input>
                            </Col>
                        </Row>
                    </form>
                </Col>
              
            </Row>
        </RightComponent>
            <ToastContainer />
        </>
    )
}