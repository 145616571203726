import { useState } from "react";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { AiOutlineHome ,AiOutlineUser,AiFillSetting,AiOutlineUnorderedList,AiOutlineDatabase,AiOutlineRead,AiOutlineLineChart} from "react-icons/ai";
import {BsFillMouseFill,BsBank} from "react-icons/bs"
import {GiThink} from "react-icons/gi"

const MenuList = [
    {menu:"Dashboard Home" , title:"",icon:<AiOutlineHome />},
    {menu:"Total User" ,title:"users",icon:<AiOutlineUser />},
    {menu:"All Pool" ,title:"allpool",icon:<AiOutlineUnorderedList />},
    {menu:"Learn",title:"training",icon:<AiOutlineRead />},
    {menu:"Strategies",title:"strategies",icon:<AiFillSetting />},
    {menu:"Virtual Pool",title:"allvirtualpool",icon:<AiOutlineUnorderedList />},
    {menu:"Real Pool",title:"allrealpool",icon:<AiOutlineUnorderedList />},
    {menu:"Mouse Over Settings",title:"mouseover",icon:<BsFillMouseFill />},
    {menu:"Connect Real Account",title:"realaccount",icon:<AiOutlineDatabase />},
    {menu:"Total Account",title:"account",icon:<BsBank />},
    {menu:"Setup",title:"mt4realaccount",icon:<AiOutlineLineChart />},
    {menu:"Analysis",title:"mt4realaccount",icon:<GiThink />}
]
export default function DashboardMenu() {
    const [position,setPosition] = useState(0)
    return (
        <SideMenu>
            <div className="list-group">
                {MenuList.map((ele,index)=>
                     <Link key={index} to={`${ele.title}`} className={`list-group-item fns-2 bold-600 list-group-item-action ${index===position?'bg-dark text-white':''}   rounded-0`} onClick={()=>setPosition(index)}><span className="me-2">{ele.icon} </span>{ele.menu}</Link> 
                )}
              
            </div>
        </SideMenu>
    )
}