import { useNavigate } from "react-router-dom"
import { clearStorage,isStorage } from "../Constant/StorageInfo"
import { userContext } from "./UserContext"
import { useEffect } from "react"
export default function Main({children}){
    const navigate = useNavigate()
    useEffect(()=>{
            if(isStorage()){
                navigate("/dashboard")
            }
            else{
                clearStorage()
                navigate("/")
            }
    },[])
    const logout =  ()=>{
            clearStorage()
            navigate("/")

    }
    const initData = {
        logout:logout
    }
    return(
      <userContext.Provider value={initData}>
        {children}
      </userContext.Provider>
    )
}