import { useEffect, useLayoutEffect } from "react";
import RightComponent from "./RightComponent";
import { Row, Col } from "react-bootstrap";

export default function DashboardHome() {
    useLayoutEffect(() => {
        document.title = "Dashbaord"
    }, [])
    return (
        <RightComponent>
            <h3 className="bold-700">Dashboard</h3>
            <Row>
                <Col>
                    <div className="card text-bg-primary mb-3 rounded-0" >
                        <div className="card-header bold-600">Total User</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">5000</h5>
                            <p className="card-text">View All</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="card text-bg-secondry mb-3 rounded-0" >
                        <div className="card-header bold-600">Total Demo Account</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">2000</h5>
                            <p className="card-text">View All</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="card text-bg-success mb-3 rounded-0" >
                        <div className="card-header bold-600">Total Real Account</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">200</h5>
                            <p className="card-text">Real Account</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="card text-bg-warning mb-3 rounded-0" >
                        <div className="card-header bold-600">Strategies</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">400 Strategies</h5>
                            <p className="card-text">View All</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="card text-bg-dark mb-3 rounded-0" >
                        <div className="card-header bold-600">Total Real Pool</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">12000</h5>
                            <p className="card-text">View All</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="card text-bg-danger mb-3 rounded-0" >
                        <div className="card-header bold-600">Total Virtual Pool</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">20000</h5>
                            <p className="card-text">View All</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="card text-bg-secondary mb-3 rounded-0" >
                        <div className="card-header bold-600">Total Pool</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">200000</h5>
                            <p className="card-text">View All Pool</p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="card text-bg-white mb-3 rounded-0" >
                        <div className="card-header bold-600">Strategies</div>
                        <div className="card-body">
                            <h5 className="card-title bold-800">400 Strategies</h5>
                            <p className="card-text">View All</p>
                        </div>
                    </div>
                </Col>
            </Row>
         

           
        </RightComponent>
    )
}