import { Row, Col } from "react-bootstrap";
import RightComponent from "../DashboardList/RightComponent";
import { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import SpinnerLoader from "../../Layout/Spinner";

import { ToastContainer, toast } from "react-toastify";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import StrategyForm from "./StrategyForm";
import { deleteStrategyApi, getAllStrategyApi } from "../../../Api/Api";
import { useNavigate } from "react-router-dom";

export default function Strategies() {
    const [loader, setLoader] = useState(true)
    const [allStrategy, setAllStrategy] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        callApi()
    }, [])
    const callApi = async () => {
        const res = await getAllStrategyApi()
        if (res.status === "success") {
            setAllStrategy(res.data)
            setLoader(false)
        }
    }
    const deleteStrategy = async (id)=>{
           const res =  await deleteStrategyApi(id)
           if(res.status == "success"){
                toast.success(res.message)
                callApi()
           }
           else{
            toast.error(res.message)
           }

    }
    const saveUpdate = ()=>{
        callApi()
    }
    return (
        <RightComponent>
            <Row>
                <Col>
                    <h3 className="bold-700">Strategies</h3>
                </Col>
            </Row>

            <StrategyForm saveUpdate = {saveUpdate}/>

            <Row className="mt-2 justify-content-center">
                <Col md={2}><SpinnerLoader status={loader} /></Col>
                <Col md={12} className="card py-4 rounded-0  text-white">

                    <table className="table text-center fns-2">

                        <thead className="bg-success text-white">
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Strategy Name</th>
                                <th scope="col">Strategy Caption</th>
                                <th scope="col">Tab 1</th>
                                <th scope="col">Tab 1 Content</th>
                                <th scope="col">Tab 2</th>
                                <th scope="col">Tab 2 Content</th>
                                <th scope="col">Delete</th>
                                <th scope="col">Edit</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                allStrategy.map((ele, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{ele.product_name}</td>
                                        <td>{ele.product_caption}</td>
                                        <td>{ele.product_tab_1}</td>
                                        <td  >
                                            <div style={{width:'200px',height:'200px',overflow:'scroll'}} dangerouslySetInnerHTML={{ __html: ele.product_content_1?.replaceAll("&lt;", "<") }}>

                                            </div>
                                        </td>
                                        <td>{ele.product_tab_2}</td>
                                        <td>
                                        <div style={{width:'200px',height:'200px',overflow:'scroll'}} dangerouslySetInnerHTML={{ __html: ele.product_content_2?.replaceAll("&lt;", "<") }}>

                                        </div>
                                        </td>
                                        <td><AiFillDelete className="text-danger fs-5" style={{ cursor: 'pointer' }} onClick={()=>deleteStrategy(ele._id)}/></td>
                                        <td><AiFillEdit className="text-warning fs-5" style={{ cursor: 'pointer' }} onClick={()=>navigate(`${ele._id}`)}/></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>

            <ToastContainer />
        </RightComponent>
    )
}